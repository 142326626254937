<template>
  <div class="downLoadWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="downBar bgWhite">
          <div class="title mb20">
            <img :src="title" alt="下载中心">
            <div @click ='course' class="titleTxt">下载教程</div>
          </div>
          <div class="downCons">
            <downItemWX :type="0"></downItemWX>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menuWX from '@/components/Menu'
  import downItemWX from '@/views/downLoad/item'
  import axios from "axios";

  export default {
    name: 'downLoad',
    components:{
      menuWX,
      downItemWX
    },
    data(){
      return {
        title:require('@/assets/imgs/downLoad/title.png'),
      }
    },
    created() {

    },
    mounted() {
      document.documentElement.scrollTop = 0
    },
    methods: {
      course(){
        this.$router.push("/gameDetail?type=10");
      }
    }
  }
</script>

<style lang="less" scoped>
  .downLoadWrap{
    .downBar{
      .title{
        text-align: center;
        position: relative;
        .titleTxt{
          position: absolute;
          top: 40%;
          right: 10%;
          background-color: #d22190;
          color: #FFF;
          font-size: 24px;
          border-radius: 10px;
          padding: 10px;
          cursor: pointer;
        }
      }
    }
  }
</style>
