<template>
  <div>
    <div class="down" v-for="(item,index) in downDatas">
      <div class="txtTitle"><span><template v-if="index == 1">{{item.versionName}}</template><template v-else>{{item.versionName}}</template></span></div>
      <div class="downCon">
        <p>版本号：{{item.version}}</p>
        <p>容量：{{item.size}}</p>
        <p>更新时间：{{item.updateDate}}</p>
        <p>安装说明：{{item.intro}}</p>
      </div>
      <div class="downBtn">
        <span><a :href="item.downUrl" target="_self">HTTP下载</a></span>
      </div>
    </div>
  </div>
</template>

<script>
  import menuWX from '@/components/Menu'
  import axios from "axios";
  export default {
    name: 'downLoad',
    components:{
      menuWX,
    },
    props:{
      type:0,     // 版本类型，0：所有版本，1：完整版，2：极速版
    },
    data(){
      return {
        downDatas:[],
      }
    },
    created() {
      this.getDownLoadData()
    },
    methods: {
      getDownLoadData(){
        axios.get('/common/getdownLoad').then((res) => {
          if(res.data.state == 200){
            if(this.type == 0){
              this.downDatas = res.data.data.downloadInfos
            }else{
              this.downDatas = res.data.data.downloadInfos.filter(item=>{
                if(item.type == this.type){
                  return item
                }
              })
            }
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      }
    },
    watch:{
      type (newVal, oldVal) {
        this.getDownLoadData()
      },
    }
  }
</script>

<style lang="less" scoped>
  .down{
    padding: 0 40px 50px;
    .txtTitle{
      padding-bottom: 5px;
      border-bottom:1px solid #000;
      span{
        padding-left:12px;
        color: #D22190;
        font-size: 26px;
        border-left: 4px solid #D22190;
      }
    }
    .downCon{
      padding:30px 0;
      p{
        font-size: 18px;
        line-height: 32px;
      }
    }
    .downBtn{
      a{
        display: inline-block;
        width: 210px;
        height: 60px;
        line-height: 60px;
        background: #D22190;
        text-align: center;
        color: #fff;
        font-size:26px;
        cursor:pointer;
      }
    }
  }
</style>
